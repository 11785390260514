<template>
  <div
    class="container-fluid mt-3"
    v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CHARGES)"
  >
    <span class="resize-loading" v-if="!chargePackage">
      <span class="loader"></span>
    </span>
    <div v-else class="charges-wrapper">
      <div class="all-infos">
        <div class="all-infos-text">
          <dl class="row">
            <dd v-if="chargePackage.name">
              {{ chargePackage.name ?? "" }}
            </dd>
            <dd v-if="chargePackage.excerpt">
             <p>{{ chargePackage.excerpt ?? "" }}</p>
            </dd>
          </dl>
        </div>
      </div>
      <div class="charges-view-table">
        <charges-view-table :charges="chargePackage.charges" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import ChargesViewTable from "../partials/charges/ChargesViewTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    ChargesViewTable,
  },

  mixins: [],

  props: {
    chargePackageId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      chargePackage: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("chargePackages/get", this.chargePackageId);
        this.chargePackage =
          this.$store.getters["chargePackages/chargePackage"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editChargePackage(chargePackage) {
      this.$emit("onEditChargePackage", chargePackage);
    },
    deleteChargePackage(chargePackage) {
      this.$emit("onDeleteChargePackage", chargePackage);
    },
  },
};
</script>
