<template>
  <el-select
    v-model="chargePackageModel"
    @change="chargePackageChanged"
    autocomplete="new-password"
    :placeholder="$t('CHARGES.SELECT')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getList"
    :loading="loading"
    :allow-create="allowCreate"
    class="w-100"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="chargePackage in chargePackages"
      :key="chargePackage.id"
      :value="chargePackage.id"
      :label="`${chargePackage.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import _ from "lodash";

export default {
  name: "chargePackage-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: true,
    },
    allowNone: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    chargePackage: {
      type: [String, Array],
      default: null,
    },
    selectedCategory: {
      type: String,
      default: null,
    },
    selectedType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      sort: "-created_at",
      chargePackageModel: this.chargePackage, // Modèle pour la sélection
      chargePackages: [], // Liste des chargePackages récupérées
      loading: false, // Indicateur de chargePackagement
      query: "", // Pour la recherche dynamique
    };
  },

  created() {
    this.getList(null, this.chargePackage); // Charger les données initiales
  },

  methods: {
    // Fonction pour récupérer les chargePackages avec support pour tableau d'IDs ou ID unique
    async getList(query = null, ids = null) {
      try {
        this.loading = true;
        let params = {
          sort: this.sort,
          filter: {
            ...(query ? { search: query } : {}),
            ...(Array.isArray(ids) ? { ids: ids } : ids ? { id: ids } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.selectedType) {
          params.filter.chargePackage_type = this.selectedType;
        }

        if (this.selectedCategory) {
          params.filter.category = this.selectedCategory;
        }

        await this.$store.dispatch("chargePackages/list", params);
        const chargePackagesArr = await this.$store.getters["chargePackages/list"];
        this.chargePackages = chargePackagesArr;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    // Emission de l'événement lorsque la sélection change
    chargePackageChanged(chargePackage) {
      this.$emit("chargePackageChanged", chargePackage);
    },
  },

  watch: {
    chargePackage(newCharge) {
      if (newCharge) {
        this.chargePackageModel = newCharge;
        // Si la valeur a changé, on recharge la liste avec l'ID ou les IDs
        if (newCharge !== this.chargePackageModel) {
          this.getList(null, newCharge);
        }
      } else {
        this.chargePackageModel = null;
      }
    },
  },
};
</script>
