<template>
  <div class="container-fluid">
    <organization-form
      :loading="loading"
      :organizationData="organization"
      :formErrors="formErrors"
      @organizationSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultOrganization from "../defaultOrganization";
import OrganizationForm from "../partials/OrganizationForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    OrganizationForm,
  },

  mixins: [alertLeave],

  data() {
    const organization = cloneDeep(defaultOrganization);
    organization.taxes = cloneDeep(defaultTaxes);
    return {
      organization: organization,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(organization) {
      this.loading = true;

      const organizationData = cloneDeep(organization);
      delete organizationData.id;
      try {
        await this.$store.dispatch("organizations/add", organizationData);
        this.$notify({
          type: "success",
          message: this.$t("ORGANIZATIONS.ORGANIZATION_ADDED"),
        });
        const organization = await this.$store.getters[
          "organizations/organization"
        ];
        this.$emit("onViewOrganization", organization, true);
        if (organization.id) {
          let locationData = organizationData;
          locationData.type = "locations";
          locationData.relationshipNames = [
            "organization",
            "manager",
            "contacts",
          ];
          locationData.is_municipal = false;
          locationData.contacts = [];
          locationData.organization = {
            type: "organizations",
            id: organization.id,
          };
          delete locationData.activePermissions;
          delete locationData.logo;
          delete locationData.reseller;
          delete locationData.taxes;
          delete locationData.owner;
          delete locationData.phone_extension;
          delete locationData.other_phones;
          await this.$store.dispatch("locations/add", locationData);
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response ? error.response.data.errors : null;
        this.loading = false;
      }
    },
  },
};
</script>
