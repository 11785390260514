<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <el-form
      class="add-form"
      @submit.prevent="
        {
        }
      "
      ref="chargePackageForm"
    >
      <div class="form-group-wrapper">
        <!-- Frais d'achat -->
        <el-form-item
          class="form-group-wrapper-item h3 w-100"
          :label="`${$t('CHARGE_PACKAGES.PURCHASE_FEES')} (*)`"
          prop="purchase_fees"
        >
          <charge-package-selector
            :chargePackage="organization?.purchase_fees.id"
            :multiple="false"
            :filterable="true"
            :showAll="false"
            @chargePackageChanged="purchaseChargePackageSelected"
          />
        </el-form-item>

        <!-- Frais d'achat -->
        <el-form-item
          class="form-group-wrapper-item h3 w-100"
          :label="`${$t('CHARGE_PACKAGES.SELLING_FEES')} (*)`"
          prop="purchase_fees"
        >
          <charge-package-selector
            :chargePackage="organization?.selling_fees.id"
            :multiple="false"
            :filterable="true"
            :showAll="false"
            @chargePackageChanged="sellingChargePackageSelected"
          />
        </el-form-item>

        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            @click.prevent="handleSubmit"
            :disabled="loading"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading" />
            {{ $t("CHARGE_PACKAGES.EDIT_CHARGE_PACKAGE") }}
          </base-button>
          <button
            type="button"
            class="btn btn-sm base-button btn-danger  ml-2"
            @click="closeFormModal"
            :disabled="loading"
          >
            {{ $t("COMMON.CANCEL") }}
          </button>
        </div>
      </div>
    </el-form>
  </modal>
</template>

<script>
import { cloneDeep } from "lodash";
import { Form, FormItem } from "element-ui";
import ChargePackageSelector from "../../ChargePackageManagement/components/ChargePackageSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ChargePackageSelector,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
  },
  props: {
    organizationId: {
      type: [String, Number],
      required: true,
    },
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      organization: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    purchaseChargePackageSelected(selected) {
      this.organization.purchase_fees = [...selected].map((item) => ({
        type: "purchase_fees",
        id: item,
      }));
    },
    sellingChargePackageSelected(selected) {
      this.organization.selling_fees = [...selected].map((item) => ({
        type: "selling_fees",
        id: item,
      }));
    },
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("organizations/get", this.organizationId);
        this.organization = this.$store.getters["organizations/organization"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit() {
      this.loading = true;
      const organizationData = cloneDeep(this.organization);

      try {
        await this.$store.dispatch("organizations/update", organizationData);
        this.$notify({
          type: "success",
          message: this.$t("ORGANIZATIONS.ORGANIZATION_UPDATED"),
        });
        this.$emit("onViewOrganization", this.organization, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
    openChargePackageViewModal() {
      this.$emit("needReload");
      this.closeFormModal();
    },
    closeFormModal() {
      this.$emit("onChangeModalState");
    },
  },
};
</script>
