<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ organization.name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_ORGANIZATIONS)"
            @click="deleteOrganization"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_ORGANIZATIONS)"
            @click="editOrganization"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <img
        v-if="organization.logo"
        :src="`${organization.logo}`"
        class="all-infos-image"
        alt="logo"
      />

      <img
        v-else
        class="all-infos-image default"
        src="/img/add-image.svg"
        alt="logo"
      />

      <div class="mb-2 mt-4">
        <p class="labe-red">
          {{ $t("COMMON.INFORMATIONS_SUR_L_ORGANISATION") }}
        </p>
      </div>

      <div class="all-infos-text">
        <dl class="row">
          <dt class="row-el">{{ $t("COMMON.NAME") }}</dt>
          <dd class="row-el">
            {{ organization.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="row-el">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="row-el">
            <div v-html="organization.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="row-el">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="row-el">
            {{ organization.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="row-el">
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd class="row-el">
            {{
              organization.phone_type
                ? $t(`COMMON.PHONE_TYPE_${organization.phone_type}`) + ":"
                : null
            }}
            {{ organization.phone }}
            {{
              organization.phone_extension
                ? ` (ext: ${organization.phone_extension})`
                : null
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="row-el">
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd class="row-el">
            <span
              v-for="(line, index) in organization.other_phones"
              :key="index"
            >
              {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
              {{ line.phoneNumber }}
              {{ line.extension ? ` (ext: ${line.extension})` : null }}
              <br />
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="row-el">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="row-el">
            {{ organization.address }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
        >
          <dt class="row-el">{{ $t("COMMON.RESELLER") }}</dt>
          <dd class="row-el">
            <reseller :reseller="organization.reseller" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <dt class="row-el">{{ $t("COMMON.OWNER") }}</dt>
          <dd class="row-el">
            <router-link :to="$objectViewRoute(organization.owner)">
              {{
                `${organization.owner.firstname} ${organization.owner.lastname} - ${organization.owner.email}`
              }}
            </router-link>
          </dd>
        </dl>
      </div>

      <div class="mb-2 mt-4">
        <p class="labe-red">
          {{ $t("COMMON.INFORMATIONS_MARCHAND") }}
        </p>
      </div>

      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.LICENCE_MARCHAND") }}</dt>
          <dd>
            {{ organization.marchand_licence }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.DATE_D_EXPIRATION_DE_LA_LICENCE_MARCHAND") }}
          </dt>
          <dd>
            {{ $timeZoneDateFormat(organization.marchand_licence_expired_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.NUMERO_SAAQ") }}</dt>
          <dd>
            {{ organization.marchand_saaq }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.DATE_D_EXPIRATION_DU_NUMERO_SAAQ") }}
          </dt>
          <dd>
            {{ $timeZoneDateFormat(organization.marchand_saaq_expired_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.OPC_LICENCE") }}</dt>
          <dd>
            {{ organization.marchand_opc_licence }}
          </dd>
        </dl>
      </div>

      <div class="mb-2 mt-4">
        <p class="labe-red">
          {{ $t("COMMON.BILLING_ADDRESS") }}
        </p>
      </div>

      <billing-informations-view :billingInformations="organization" />
    </div>
  </div>
</template>

<script>
import models from "@/constants/models";
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";
import BillingInformationsView from "@/components/BillingInformationsView.vue";

export default {
  name: "organization-view-global",

  components: { BillingInformationsView },

  props: ["organization"],

  data() {
    return {
      models: models,
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
    };
  },

  computed: {},

  created() {},

  methods: {
    editOrganization() {
      this.$emit("onEditOrganization", this.organization);
    },
    deleteOrganization() {
      this.$emit("onDeleteOrganization", this.organization);
    },
  },

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
