export const CATEGORY_FEE = "fee";
export const CATEGORY_COMMISSION = "commission";
export const TYPE_PALIER = "palier";
export const TYPE_POURCENTAGE = "pourcentage";
export const TYPE_MONTANT_FIXE = "montant_fixe";

export const CHARGE_CATEGORIES = {
  '':'',
  [CATEGORY_FEE]: "fee",
  [CATEGORY_COMMISSION]: "commission"
};

export const CHARGE_TYPES = {
    '':'',
    [TYPE_POURCENTAGE]: "pourcentage",
    [TYPE_MONTANT_FIXE]: "montant_fixe",
    [TYPE_PALIER]: "palier",
  };
  