<template>
  <div class="paliers">
    <div v-if="tiers.length" class="row">
      <div class="col" v-for="(row, index) in tiers" :key="index">
        <div>
          <strong>{{ $t("CHARGES.MIN") }}: </strong>
          {{ row.min_value || 0 }}<br />
          <strong>{{ $t("CHARGES.MAX") }}: </strong>
          {{ row.max_value || "∞" }}
        </div>
        <div>
          <strong>{{ $t("CHARGES.COUT") }}: </strong>
          <span v-if="row.amount"> {{ currency }}{{ row.amount }} </span>
          <span v-else-if="row.percentage"> {{ row.percentage }}% </span>
        </div>
        <!-- <p>{{ row.excerpt ?? "" }}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
export default {
  name: "TiersList",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    tiers: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    currency: {
      type: String,
      default: "$",
    },
  },
};
</script>

<style scoped>
.paliers {
  background-color: #efefef;
  border-radius: 10px;
  padding: 5px;
  margin-top: 0px !important;
}
</style>
