<template>
  <div class="all-infos-text">
    <dl
      class="row"
      v-if="
        billingInformations.billing_entity_type ===
        BILLING_ENTITY_TYPE_INDIVIDUAL
      "
    >
      <dt>{{ $t("COMMON.LASTNAME") }}</dt>
      <dd>
        {{ billingInformations.billing_lastname }}
      </dd>
    </dl>

    <dl
      class="row"
      v-if="
        billingInformations.billing_entity_type ===
        BILLING_ENTITY_TYPE_INDIVIDUAL
      "
    >
      <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
      <dd>
        {{ billingInformations.billing_firstname }}
      </dd>
    </dl>

    <dl
      class="row"
      v-if="
        billingInformations.billing_entity_type === BILLING_ENTITY_TYPE_COMPANY
      "
    >
      <dt>{{ $t("COMMON.COMPANY_NAME") }}</dt>
      <dd>
        {{
          billingInformations.billing_company_name != "NOT_DEFINED"
            ? billingInformations.billing_company_name
            : ""
        }}
      </dd>
    </dl>

    <dl class="row">
      <dt>{{ $t("COMMON.COUNTRY") }}</dt>
      <dd>
        {{
          billingInformations.billing_country != "NOT_DEFINED"
            ? billingInformations.billing_country
            : ""
        }}
      </dd>
    </dl>

    <dl class="row">
      <dt>{{ $t("COMMON.STATE") }}</dt>
      <dd>
        {{
          billingInformations.billing_state != "NOT_DEFINED"
            ? billingInformations.billing_state
            : ""
        }}
      </dd>
    </dl>

    <dl class="row">
      <dt>{{ $t("COMMON.CITY") }}</dt>
      <dd>
        {{
          billingInformations.billing_city != "NOT_DEFINED"
            ? billingInformations.billing_city
            : ""
        }}
      </dd>
    </dl>

    <dl class="row">
      <dt>{{ $t("COMMON.ZIPCODE") }}</dt>
      <dd>
        {{
          billingInformations.billing_zipcode != "NOT_DEFINED"
            ? billingInformations.billing_zipcode
            : ""
        }}
      </dd>
    </dl>

    <dl class="row">
      <dt>{{ $t("COMMON.ADDRESS") }}</dt>
      <dd>
        {{
          billingInformations.billing_address != "NOT_DEFINED"
            ? billingInformations.billing_address
            : ""
        }}
      </dd>
    </dl>

    <dl class="row">
      <dt>{{ $t("COMMON.EMAIL") }}</dt>
      <dd>
        {{
          billingInformations.billing_email != "NOT_DEFINED"
            ? billingInformations.billing_email
            : ""
        }}
      </dd>
    </dl>
  </div>
</template>

<script>
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
  BILLING_ENTITY_TYPE_COMPANY,
} from "@/constants/billingInformations";

export default {
  name: "billing-informations-view",

  components: {},

  props: ["billingInformations"],

  data() {
    return {
      BILLING_ENTITY_TYPE_INDIVIDUAL: BILLING_ENTITY_TYPE_INDIVIDUAL,
      BILLING_ENTITY_TYPE_COMPANY: BILLING_ENTITY_TYPE_COMPANY,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    billingInformations(billingInformations) {},
  },
};
</script>
